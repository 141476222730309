.imgIcon {
    animation: loading 1s infinite;
}

@keyframes loading {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}