.bannar-wrapper {
    height: 100vh;
    width: 100vw;
}

.bannar-wrapper .navbar {
    position: fixed;
    max-width: 100vw !important;
    top: 0;
    left: 0;
    z-index: 99999999;
    transition: all 0.5s ease-in-out;
}

.bannar-wrapper .bannerVideo {
    width: 100vw;
    max-width: 100vw !important;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;

}

.bannar-wrapper .hero-content {
    height: 100%;
    position: absolute;
    display: flex;
    top: 0;
    flex-direction: column;
    justify-content: justify;
    align-items: center;

}

video{
  /* video effect control change the number to incrase or reduce effect*/
  filter:brightness(40%);}
