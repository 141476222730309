.artwrapper {
    max-width: 100%;
    position: relative;
  }

  .artwrapper .navbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    transition: all 0.5s ease-in-out;
  }



